@import url("https://fonts.googleapis.com/css?family=Roboto");

body {
  font-family: "Roboto", sans-serif !important;
}

.acroventus-login {
  position: absolute;
  top: 1em;
  left: 1em;
}

.rinomedia-login {
  margin-top: 4.5em;
}

.komatsu-login {
  position: absolute;
  top: 1em;
  right: 1em;
}

.login-logos {
  min-height: 70px;
}

.login-version {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.simulated-input {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.block {
  display: block !important;
}

.h6-important {
  font-size: 0.875rem !important;
}

.DayPickerInput {
  width: 100%;
}

.switch-slider {
  z-index: 0;
}

.page-link {
  z-index: 0 !important;
}

.ag-cell-edit-input {
  width: 148px;
  height: 25px;
}

@media (max-width: 600px) {
  .player-container {
    width: 350px;
  }
  .player-options{
    width: 320px;
  }
  .player-progress-container {
    width: 300px;
  }
}

.player-progress-value:after {
  background-color: #d22e66;
}

.DayPickerInput {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}


.img-profile{
  max-height: 60px
}
